<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg icon-app1 translations-sync-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "translations-sync.translations-sync.translations-sync"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <div class="clebex-item-section">
      <clebex-button
        :label="
          displayLabelName(
            'translations-sync.translations-sync.translations-sync'
          )
        "
        @buttonPressed="syncTranslations"
      >
      </clebex-button>
    </div>
    <info-modal
      v-if="show"
      :show="show"
      icon="checkmark"
      modalClass="success"
      @close="closeModal"
    >
      <h3 class="modal-title">
        {{
          displayLabelName(
            "translations-sync.translations-sync.translations-synced"
          )
        }}
      </h3>
    </info-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import ClebexButton from "@/components/global/Button";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "SyncTranslations",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    ClebexButton,
    InfoModal: defineAsyncComponent(() =>
      import("@/components/global/InfoModal")
    )
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    syncTranslations() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(apiEndpoints.master.syncTranslations)
        .then(() => {
          this.show = true;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          if (this.fileToUpload) {
            this.uploadPhoto();
          }
        });
    },
    closeModal() {
      this.show = false;
    }
  }
};
</script>
